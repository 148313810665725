import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import classes from './ResultScreen.module.scss'

/**
 * ResultScreen component
 **/

const ResultScreen = ({ title, icon = null, buttonName, description }) => {
  return (
    <div
      className={classes.ResultScreen}
    >
      {icon && <Img fixed={icon} style={{ width: '36px', height: '36px' }} />}
      <p className={classes.Status}
        dangerouslySetInnerHTML={{ __html: title }} />
      <div
        className={classes.Message}
        dangerouslySetInnerHTML={{ __html: description }} />
      <Link
        to={'/'}
        className={classes.HomeLink}
        dangerouslySetInnerHTML={{ __html: buttonName }} />
    </div>
  )
}

export default ResultScreen
